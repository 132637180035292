import './main.scss';
import React, { useState, useContext } from 'react';
import './App.css';
import { withRouter } from 'react-router-dom';
import { Routes } from './Routes';
import { Header } from './containers/Header';
import { Footer } from './containers/Footer';
import { httpErrorHandler } from './containers/Errors/HttpInterceptor';
import { getTrail, Breadcrumb } from './components/Govuk';
import { FeedbackBanner } from './containers/Feedback/FeedbackBanner';
import { CookieBanner } from './containers/CookieBanner/CookieBanner';
import { AuthContext } from './components/auth/AuthContext';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getCookie } from './cookie';

export const App = (props) => {
  // Set up error handler here so it has access to router props to route to error page
  httpErrorHandler(props);
  const { isInternalUser } = useContext(AuthContext);
  const { pushInstruction } = useMatomo();
  const internalOrExternal = isInternalUser ? 'Internal' : 'External';

  pushInstruction('requireConsent');

  const constentGiven = getCookie('seen_cookie_message') === 'Yes';
  if (constentGiven) {
    pushInstruction('setConsentGiven');
  }

  pushInstruction(
    'setCustomVariable',
    1,
    'VisitorType',
    internalOrExternal,
    'visit'
  );

  const [uuid, setUuid] = useState();
  const trail = getTrail(props.location.pathname);

  return (
    <>
      <CookieBanner />
      <div className="gov-app" style={{ overflowX: 'hidden' }}>
        <Header />
        <FeedbackBanner />
        <div className="govuk-width-container">
          {trail && <Breadcrumb trail={trail} />}
        </div>
        <div className="gov-content govuk-width-container">
          <Routes setUuid={setUuid} uuid={uuid} />
        </div>
        <Footer style={{ flex: 0 }} />
      </div>
    </>
  );
};

export default withRouter(App);
