import React, { useEffect } from 'react';
import { Enum } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { SELECT_REPORT_REASON_ERROR_MESSAGE } from '../validation-utils';
import { ErrorList } from '../components/ErrorList';

const AboutIncidentSchema = Yup.object().shape({
  about_incident: Yup.object().shape({
    report_type: Yup.string().required(SELECT_REPORT_REASON_ERROR_MESSAGE),
  }),
});

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validationSchema: AboutIncidentSchema,
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    const { matomo, editMode, sections } = values;
    matomo.trackEvent({
      category: 'About the Incident',
      action: 'Completed',
    });
    delete values.matomo;
    let nextPage = '';
    switch (values.about_incident.report_type) {
      case 'Theft':
        nextPage = 'crime-reference';
        break;
      default:
        nextPage = 'transaction-date';
    }
    if (editMode) {
      nextPage = 'review-answers';
    }
    sections.add('about-incident');
    formikBag.props.updateValues(values, nextPage);
  },
});

const AboutIncident = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'About the Incident',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'About the incident - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`transaction-date`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-full">
            <ErrorList />
            <span className="govuk-caption-l">About the incident</span>
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <fieldset className="govuk-fieldset">
                <div className="govuk-grid-column">
                  <Enum
                    enumValues={[
                      { value: 'A completed purchase' },
                      { value: 'An attempted purchase' },
                      { value: 'Theft' },
                      { value: 'Loss' },
                    ]}
                    name="about_incident.report_type"
                    direction="col"
                    questionLarge="What would you like to report?"
                  />
                </div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label={'Continue'}
                >
                  Continue
                </button>
              </fieldset>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const AboutIncidentWithFormik = formikWrapper(AboutIncident);

export { AboutIncidentWithFormik as AboutIncident };
