import * as Yup from 'yup';
import moment from 'moment';
import { cloneDeep, get, uniqBy, unset } from 'lodash';

/**
 * Re-usable bits for validation throughout One Off SAR journey
 */

// regex to loosely match telephone number formats
// e.g. (+44) 2744 891891 x556
export const TELEPHONE_MATCHER = /(^[\d\s+\-,()x]+$)/;
export const EMAIL_ERROR_MSG =
  'Enter an email address in the correct format, like name@example.com';
export const TELEPHONE_ERROR_MESSAGE =
  'Enter a telephone number, like 01632 960 001, 07700 900 982 or +44 0808 157 0192';
export const DOB_ERROR_MESSAGE = 'Enter a valid date which is in the past';
export const DATE_ERROR_MESSAGE = 'Enter a valid date';
export const FULL_NAME_ERROR_MESSAGE = 'Enter your full name';
export const ORGANISATION_ERROR_MESSAGE = 'Select your reporter type ';
export const ORGANISATION_ERROR_NAME_MESSAGE = 'Organisation name is required';
export const NUMBER_ERROR_MESSAGE = 'Provide only numbers';
export const RETAILER_NAME_ERROR_MESSAGE = 'Enter the retailer name';
export const RETAILER_NAME_SIZE_ERROR_MESSAGE =
  'Provide name of 256 characters or less';
export const IP_ADDRESS_INVALID_MESSAGE = 'IP address is invalid';
export const IP_ADDRESS_MATCHER =
  /^(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))((\.(([0-9])|([1-9][0-9])|(1([0-9]{2}))|(2[0-4][0-9])|(25[0-5]))){3})$/;
export const SELECT_REPORT_REASON_ERROR_MESSAGE = 'Select one incident type';
export const SELECT_KNOWS_PERSONS_DETAILS_ERROR_MESSAGE =
  'Select an option about the suspicious person(s) you are reporting';
export const WEIGHT_OR_ITEM_ERROR_MSG =
  'Enter whether product sold by weight or item?';
export const IS_DISCOUNT_ERROR_MSG =
  'Enter whether product had a discount or promotion applied?';
export const TRANSACTION_TYPE_REQUIRED = 'Select the type of transaction';
export const TRANSACTION_TYPE_OTHER = 'Enter the location of the activity';
export const TRANSACTION_TYPE_OTHER_PAYMENT = 'Enter the other form of payment';
export const THIRD_PARTY_OPTION_REQUIRED =
  'Select whether or not the transaction was carried out via a third-party';
export const PAYMENT_METHOD_REQUIRED =
  'Select which payment method was used for the transaction';
export const LENGTH_SIX_ERROR = 'Must be exactly six characters';
export const LENGTH_FOUR_ERROR = 'Must be exactly four characters';
export const CRIME_REFERENCE_ERROR_MESSAGE = 'Enter crime reference number';
export const ACTIVITY_PLACE_ERROR_MESSAGE =
  'Please specify where the activity took place';
export const EPP_LICENSE_NUMBER_ERROR_MESSAGE = 'Enter your EPP license number';
export const BUSINESS_NAME_ERROR_MESSAGE = 'Enter the name of the business';

const dateValidationSchema = (parent, errorMessage, validationCallback) => {
  return {
    [parent]: Yup.object().shape({
      day: Yup.number()
        .typeError(errorMessage)
        .min(1, errorMessage)
        .max(31, errorMessage),
      month: Yup.number()
        .typeError(errorMessage)
        .min(1, errorMessage)
        .max(12, errorMessage),
      year: Yup.number()
        .typeError(errorMessage)
        .min(1900, errorMessage)
        .max(moment().year(), errorMessage)
        .test(parent, errorMessage, validationCallback),
    }),
  };
};

const dobValidationCallback = function () {
  const today = moment().startOf('day');
  const parent = this.parent;
  const day = parent.day;
  const month = parent.month;
  const year = parent.year;
  if (!year && !month && !day) {
    return true;
  }
  return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').isBefore(
    today,
    'year'
  );
};
const transactionValidationCallback = function () {
  const today = moment().endOf('day');
  const parent = this.parent;

  if (!parent.year && !parent.month && !parent.day) {
    return true;
  }
  return moment(
    `${parent.year}-${parent.month}-${parent.day}`,
    'YYYY-MM-DD'
  ).isBefore(today);
};
const transactionDispatchValidationCallback = function () {
  const parent = this.parent;

  if (!parent.year && !parent.month && !parent.day) {
    return true;
  }
  return moment(
    `${parent.year}-${parent.month}-${parent.day}`,
    'YYYY-MM-DD'
  ).isValid();
};

export const getDobValidationSchema = () =>
  dateValidationSchema('dob', DOB_ERROR_MESSAGE, dobValidationCallback);

export const getTransactionDateValidationSchema = () =>
  dateValidationSchema(
    'date',
    DATE_ERROR_MESSAGE,
    transactionValidationCallback
  );

export const getDispatchDateValidationSchema = () =>
  dateValidationSchema(
    'dispatch_date',
    DATE_ERROR_MESSAGE,
    transactionDispatchValidationCallback
  );

export const getDeliveryDateValidationSchema = () =>
  dateValidationSchema(
    'delivery_date',
    DATE_ERROR_MESSAGE,
    transactionDispatchValidationCallback
  );

/**
 * Traverse the error object's first entries to get first error on Formik form
 * @param errors - Formik errors object
 * @param path
 * @returns {string|*}
 */
export const getFirstErrorFieldName = (errors, path = '') => {
  let errorKeys = Object.keys(errors || {});
  if (errorKeys.length) {
    const nextPathStep = errorKeys[0];
    if (typeof errors[nextPathStep] === 'object') {
      path = path ? `${path}.${nextPathStep}` : nextPathStep;
      return getFirstErrorFieldName(errors[nextPathStep], path);
    }
    if (Array.isArray(errors[nextPathStep])) {
      path = `${path}.${errors[nextPathStep].length - 1}`;
      return getFirstErrorFieldName(errors[nextPathStep][0], path);
    }
    return `${path}.${nextPathStep}`;
  }
  return path;
};

/**
 * Returns array for all error paths which map to input field id, and corresponding error message
 * @param errors - Formik errors object
 * @returns {[]}
 */
export const getFlatErrorList = (errors = {}) => {
  const errorsCopy = cloneDeep(errors);
  const result = [];
  let path = getFirstErrorFieldName(errorsCopy);

  while (path) {
    const message = get(errorsCopy, path);
    if (typeof message === 'string') {
      result.push({
        message,
        path,
      });
    }
    unset(errorsCopy, path);
    path = getFirstErrorFieldName(errorsCopy);
  }

  return result;
};

/**
 * The list of errors at top of page need to show the error message
 * and the corresponding label for the form field the error occurred
 * on. This function will merge the form question label and the error
 * message, and return the list of errors with the new error message.
 *
 *
 * @param errors - Formik errors object
 */
export const getLabelledFlatErrorList = (errors) => {
  const flattenedErrorsList = getFlatErrorList(errors);
  for (const errorEntry of flattenedErrorsList) {
    errorEntry.questionName = getErrorPath(errorEntry.path);
  }
  return uniqBy(flattenedErrorsList, 'questionName');
};

/**
 * Function to get the formik input field path.
 * Dates are a special case, as the label is attached to the three form fields
 * for day, month and year. Need to convert from error path to question name:
 * e.g.
 *    buyer.dob.day -> buyer.dob
 * @param errorEntry
 * @returns {*}
 */
export const getErrorPath = (errorEntry) => {
  let path;
  if (/\.day|\.month|\.year/.test(errorEntry)) {
    const datePath = errorEntry.match(
      // strip the last segment of path
      /([\w.]+)(?=\.year|\.month|\.day)/
    );
    if (datePath && datePath.length > 0) {
      path = datePath[0];
    }
  } else {
    path = errorEntry;
  }
  return path;
};
