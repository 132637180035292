import React, { useEffect } from 'react';
import { TextArea, InputBox } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'About the Person - Vehicle Info',
      action: 'Completed',
    });
    delete values.matomo;
    let next = '';
    if (values.about_incident.report_type === 'Theft') {
      next = 'crime-reference';
    } else if (values.about_person.person_known === 'Yes') {
      next = 'buyer-information';
    } else {
      next = 'product-information';
    }
    if (values.editMode) {
      next = 'review-answers';
    }
    values.sections.add('vehicle-information');
    formikBag.props.updateValues(values, next);
  },
});

const VehicleInfo = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'About the Person - Vehicle Info',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'Vehicle details - Report Suspicious Chemical Activity';
  }, []);

  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(
              `sections`,
              values.sections.delete(`vehicle-information`)
            );
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <fieldset className="govuk-fieldset">
              <legend className="govuk-fieldset__legend govuk-fieldset__legend--l">
                <h1 className="govuk-fieldset__heading">Vehicle description</h1>
                <p className="govuk-body">
                  Add any information you have available.
                </p>
              </legend>
              <Form autoComplete="on" onSubmit={formik.handleSubmit}>
                <div className="govuk-grid-column">
                  <TextArea
                    question="Describe the vehicle"
                    name="about_person.vehicle_description"
                    style="m"
                  />
                  <InputBox
                    question="What is the vehicle registration?"
                    name="about_person.vehicle_registration"
                    subText="If you do not know you can leave this blank."
                  />
                </div>
                <button
                  type="submit"
                  data-prevent-double-click="true"
                  className="govuk-button"
                  data-module="govuk-button"
                  data-test={`button-continue`}
                  aria-label={'Continue'}
                >
                  {'Continue'}
                </button>
              </Form>
            </fieldset>
          </div>
        </div>
      </main>
    </div>
  );
};

const VehicleInfoWithFormik = formikWrapper(VehicleInfo);

export { VehicleInfoWithFormik as VehicleInfo };
