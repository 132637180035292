import React, { useEffect } from 'react';
import { TextArea } from '../components';
import { Form, useFormikContext, withFormik } from 'formik';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { useHistory } from 'react-router-dom';

const formikWrapper = withFormik({
  mapPropsToValues: (props) => ({
    ...props.initialValues,
  }),
  validateOnMount: false,
  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: (values, formikBag) => {
    values.matomo.trackEvent({
      category: 'Other',
      action: 'Completed',
    });
    delete values.matomo;
    values.sections.add('more-info');
    formikBag.props.updateValues(values, 'review-answers');
  },
});

const MoreInfo = ({ values, setFieldValue }) => {
  const formik = useFormikContext();
  const { trackPageView, trackEvent } = useMatomo();
  const history = useHistory();
  values.matomo = { trackEvent };
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Other',
      action: 'Started',
    });
    window.scrollTo(0, 0);
    document.title = 'More information - Report Suspicious Chemical Activity';
  }, []);
  return (
    <div>
      <a
        className="govuk-back-link"
        href="#"
        onClick={() => {
          if (!values.editMode) {
            setFieldValue(`sections`, values.sections.delete(`more-info`));
          }
          history.goBack();
        }}
      >
        Back
      </a>
      <main
        className="govuk-main-wrapper govuk-main-wrapper--auto-spacing"
        id="content"
        role="main"
        tabIndex={0}
      >
        <div className="govuk-grid-row">
          <div className="govuk-grid-column-two-thirds">
            <Form autoComplete="on" onSubmit={formik.handleSubmit}>
              <div className="govuk-grid-column">
                <TextArea
                  headingLarge="Do you have any more information?"
                  name="transaction.further_info"
                  subText={
                    <div>
                      <p>
                        Provide any information that you have not already
                        included elsewhere in your report, and let us know if
                        you have any evidence that can be shared, such as:
                      </p>
                      <ul>
                        <li>CCTV footage</li>
                        <li>email receipts</li>
                        <li>media</li>
                        <li>documentation handled by the person</li>
                        <li>an existing police report</li>
                      </ul>
                    </div>
                  }
                  hintId="other-hint"
                />
              </div>
              <button
                type="submit"
                data-prevent-double-click="true"
                className="govuk-button"
                data-module="govuk-button"
                data-test={`button-continue`}
                aria-label="continue"
              >
                Continue
              </button>
            </Form>
          </div>
        </div>
      </main>
    </div>
  );
};

const MoreInfoWithFormik = formikWrapper(MoreInfo);

export { MoreInfoWithFormik as MoreInfo };
