/*eslint no-irregular-whitespace: ["error", { "skipJSXText": true }]*/
import React, { useEffect, useContext } from 'react';
import { StartButton } from '../../components/Govuk';
import withAuthorisation from '../../components/auth/WithAuthorisation';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { getConfig } from '../../Config';
import { AuthContext } from '../../components/auth/AuthContext';
import { Link } from 'react-router-dom';

const config_envt = getConfig().environment;
const StartButtonForPreprod = () => {
  return (
    <div>
      <StartButton
        url="/upload"
        dataTest="start-upload-button"
        content="Upload test report"
        style={{ borderRadius: '50%' }}
      />
      <div className="govuk-body">
        <p className="govuk-body">
          <Link
            to="previous-uploads"
            className="govuk-link"
            data-test={`previously-uploaded-reports-link`}
          >
            View your previously uploaded reports
          </Link>
          .
        </p>
        <br></br>
      </div>
    </div>
  );
};

const StartButtonOthers = () => {
  return (
    <div>
      <StartButton
        url="/upload"
        dataTest="start-upload-button"
        content="Upload report"
        style={{ borderRadius: '50%' }}
      />
      <div className="govuk-body">
        <ul className="govuk-list govuk-list--bullet">
          <li>
            To upload test data, go to{' '}
            <a
              className="govuk-notification-banner__link"
              href="https://crs.portal-preprod.dsa.homeoffice.gov.uk/login"
            >
              test site
            </a>
            .
          </li>
          <li>
            {' '}
            <Link
              to="previous-uploads"
              className="govuk-link"
              data-test={`previously-uploaded-reports-link`}
            >
              View your previously uploaded reports
            </Link>
            .
          </li>
        </ul>
      </div>
    </div>
  );
};

const OneOffSiteBanner = () => {
  return (
    <div>
      <br></br>
      <h2 className="govuk-heading-l" data-test="one-off-sar-header">
        One-off reporting
      </h2>
      <div className="govuk-body" data-test="one-off-sar-body">
        <p>
          <Link
            to="sar/before-starting"
            className="govuk-link"
            data-test="one-off-sar-link"
          >
            Use the one-off reporting portal
          </Link>{' '}
          to submit a report if you:
        </p>

        <ul className="govuk-list govuk-list--bullet">
          <li>
            have an urgent 'high risk' report that should be reported sooner
            than your routine submissions
          </li>
          <li> are experiencing issues with the portal</li>
        </ul>
      </div>
    </div>
  );
};

const TestSiteBanner = () => {
  return (
    <div
      class="govuk-notification-banner"
      role="region"
      aria-labelledby="govuk-notification-banner-title"
      data-module="govuk-notification-banner"
      style={{ background: '#d4351c', borderColor: '#d4351c', margin: 0 }}
    >
      <div class="govuk-notification-banner__header">
        <h2
          class="govuk-notification-banner__title"
          id="govuk-notification-banner-title"
        >
          This is a testing site
        </h2>
      </div>
      <div class="govuk-notification-banner__content">
        <p class="govuk-body">
          Use this site:​
          <ul>
            <li>to test file uploads​</li>
            <li>for training purposes</li>
          </ul>
          Do <u>not</u> upload your regular suspicious activity reports here.
          Upload them to the{' '}
          <a
            class="govuk-notification-banner__link"
            href="https://report-suspicious-chemical-activity.dsa.homeoffice.gov.uk/login"
          >
            live reporting site
          </a>
          .
        </p>
      </div>
    </div>
  );
};

const Home = () => {
  const { trackPageView, trackEvent } = useMatomo();
  const { isOneOffRetailer } = useContext(AuthContext);
  useEffect(() => {
    trackPageView();
    trackEvent({
      category: 'Report Suspicious Chemical Activity - Home Page',
      action: 'Visited',
    });
  }, [trackPageView, trackEvent]);

  return (
    <>
      <main
        className="govuk-main-wrapper"
        id="content"
        role="main"
        data-testid="content"
        tabIndex={0}
      >
        <div>
          {config_envt === 'preprod' ? <TestSiteBanner /> : ''}
          {!isOneOffRetailer() && (
            <div className="govuk-content">
              {config_envt === 'preprod' ? <br /> : ''}
              <h1 className="govuk-heading-l" data-test="homepage-header">
                {config_envt === 'preprod'
                  ? 'Upload test reports'
                  : 'Upload regular reports'}
              </h1>
              <p className="govuk-body" data-test="header-sub-text">
                If you do not use an API (Application Programming Interface) to
                upload regular reports, use the button below to manually upload
                your report.
              </p>

              {config_envt === 'preprod' ? (
                <StartButtonForPreprod />
              ) : (
                <StartButtonOthers />
              )}
            </div>
          )}
          <OneOffSiteBanner />

          <h2
            className="govuk-heading-l"
            data-test="aditional-information-header"
          >
            Additional information and guidance
          </h2>
          <h2 className="govuk-heading-s" data-test={`api-docs-header`}>
            API reporting
          </h2>
          <p className="govuk-body" data-test={`API documentation-body`}>
            <a
              href="./docs/Guidance_for_API_reporting.pdf"
              className="govuk-link"
              download
              data-test={`api-documentation-link`}
            >
              Technical guidance
            </a>{' '}
            and <a href="/Upload">reporting templates </a>
            are available for API reporters.
          </p>
          <h2 className="govuk-heading-s" data-test={`precursor-list-header`}>
            Explosive precursors and poisons
          </h2>
          <p className="govuk-body" data-test={`Precursor list-body`}>
            You can read the{' '}
            <a
              href="https://www.gov.uk/government/publications/supplying-explosives-precursors/supplying-explosives-precursors-and-poison"
              target="_blank"
              className="govuk-link"
              data-test={`Precursor list-link`}
            >
              latest gov.uk guidance on regulated and reportable substances
            </a>
            , which also includes the full chemical list.
          </p>
          <h2 className="govuk-heading-l">Need help?</h2>
          <p className="govuk-body">
            If you have any questions or need help, email{' '}
            <a href="mailto:ChemicalReportingTeam@homeoffice.gov.uk">
              ChemicalReportingTeam@homeoffice.gov.uk
            </a>
            <br />
            This inbox is monitored by the team Monday to Friday 9am to 5pm. It
            is not monitored on bank holidays.
          </p>
        </div>
      </main>
    </>
  );
};

const HomeWithAuth = withAuthorisation({ forwardUrl: '/home' })(Home);
export { HomeWithAuth as Home };
